<template>
    <div class="flex-in-middle">
        <el-icon class="el-icon-loading"></el-icon>
    </div>
</template>
<script>

import CommonMixin from "../common/mixins/common-mixin";
import VueCookie from 'vuecookie'
import {base64encode} from "@/common/base64"

export default {
    mixins: [CommonMixin],
    created() {
        // 判断用户是否已经登录了
        const token = this.$route.query._ut || this.user.token;
        if (token) {
            const _t = decodeURIComponent(this.$route.query._t) || '/portal';
            this.$api('ValidateToken',{token:token}).then(res => {
                if (res.data.success) {
                    this.setUserInfo(res.data.data);
                    this.$router.push(_t);
                } else {
                    this.clear();
                    this.checkSSO();
                }
            }).catch(() => {
                this.clear();
                this.checkSSO();
            });
        } else {
            this.checkSSO();
        }
    },

    methods: {
        jumpToSSO() {
            // 跳转到SSO登录页面
            const _t = this.$route.query._t ? encodeURIComponent(base64encode(this.$route.query._t)) : '';
            const state = '&state=' + _t;
            let return_url;
            if (this.$organization.sso_type === 'cas') {
                return_url = this.$organization.signin_url + (this.$route.query._t ? '?_tt=' + _t : '');
                window.location.href = this.$organization.sso + encodeURIComponent(return_url);
            } else {
                return_url = this.$organization.signin_url;
                window.location.href = this.$organization.sso + encodeURIComponent(return_url) + state;
            }
        },
        checkSSO(){
            if (this.$organization.org === 'pep') { // 判断是否在cookie中有了token
                const token = VueCookie.get('LtpaToken');
                if (token) {
                    this.$api('User.TdsToken', {token: token}).then(res => {
                        if (res.data.success) {
                            this.setUserInfo(res.data.data);
                            const _t = decodeURIComponent(this.$route.query._t) || '/portal';
                            this.$router.push(_t);
                        } else {
                            this.$router.replace('/sign-in?_t=' + (this.$route.query._t || '/portal'));
                        }
                    }).catch(() => {
                        this.$router.replace('/sign-in?_t=' + (this.$route.query._t || '/portal'));
                    })
                } else {
                    this.$router.replace('/sign-in?_t=' + (this.$route.query._t || '/portal'));
                }
            } else if (this.$organization.sso &&  this.$organization.sso_play) {
                this.jumpToSSO();
            } else {
                this.$router.replace('/sign-in?_t=' + (this.$route.query._t || '/portal'));
            }
        }
    }
}
</script>


